import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Shop from "../components/shop.jsx"

import * as styles from "../components/index.module.css"
import "./index.css"
import data from '../data/data.json'
import $ from "jquery";
import ReactPaginate from 'react-paginate';
import search from '../images/search.svg'
import favs from '../images/heart.svg'
import share from '../images/share.svg'
import ReactTooltip from 'react-tooltip';
import { navigate } from 'gatsby'

import ProductItem from "../components/productItem"
//import numberOfWords from "word-frequency-basic"


class IndexPage extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { };

  }
  
  render() {

    return (
    <div>
      <Shop location={this.props.location}/>
    </div>)
  }
}


export default IndexPage
